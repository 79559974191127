import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import Intro from "../components/intro"
import FourFeatures from "../components/fourFeatures"
import DownloadInStores from "../components/downloadInStores"
import CoreFeature from "../components/coreFeature"
import FullScreenImage from "../components/fullScreenImage"
import feature1 from "../images/appshots/feature-1.png"
import feature2 from "../images/appshots/feature-2.png"
import feature3 from "../images/appshots/feature-3.png"
import feature4 from "../images/appshots/feature-4.png"
import feature5 from "../images/appshots/feature-5.png"
import AppVideo from "../components/appVideo"
import StartNow from "../components/startNow"
import SuccessStories from "../components/successStories"

const IndexPage = () => {
  return (
    <Layout theme="smartwerker" lang="es">
      <SEO title="Smartwerker" />
      <div id="about"/>
      <Intro
        titleColored="Smartwerker."
        title="La aplicación de los artesanos"
        description="Capturar hojas de tiempo digitalmente, intercambiar información a través del chat, almacenar y compartir documentos, crear tareas y mucho más!"
        btnLabel="Pruebe ahora gratis"
      />
      <div id="features"/>
      <FourFeatures 
        title1="Tareas"
        description1="Crea tareas y asígnalas a tus colegas. Las tareas completadas se marcan y no se olvida nada."
        title2="Material"
        description2="Con las listas de carga, ya no hay escasez de material en el sitio del cliente. El material instalado puede ser visto en cualquier momento."
        title3="Registro de tiempo"
        description3="Ya no hay que esperar a las hojas de asistencia de los colegas. Las horas trabajadas se enumeran en tiempo real por proyecto."
        title4="Imágenes y documentos"
        description4="Los planos, el progreso de la construcción y las notas de entrega se almacenan digitalmente para el proyecto. Esto significa que todo lo relevante puede ser encontrado en un lugar en cualquier momento."
      />
      <DownloadInStores 
        title="LA APLICACIÓN NÚMERO 1 PARA ARTESANOS TAMBIÉN EN EL NAVEGADOR"
        description="Smartwerker conecta a los colegas en la oficina y en el sitio del cliente. Toda la información necesaria (documentos, material, hojas de asistencia, informes, fotografías) y los mensajes de chat pueden compartirse y accederse dentro de la aplicación móvil y en el navegador - en tiempo real."
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNCIÓN"
        title="Proyectos"
        description="Crea un proyecto en Smartwerker para completar tu pedido. Esto le permite utilizar todas las funciones de Smartwerker y mantener siempre una buena visión general de sus proyectos/órdenes."
        btnLabel="Aprende más."
        btnTarget="https://smartwerker.app/"
        img={feature1}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNCIÓN"
        title="Crear y firmar informes"
        description="Introduzca las horas trabajadas y el material consumido en un informe. Una vez creado el informe, puede ser enviado al cliente por correo electrónico e incluso firmado digitalmente en el sitio."
        btnLabel="Aprende más."
        btnTarget="https://smartwerker.app/"
        img={feature2}
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNCIÓN"
        title="Chat y mensajes de voz"
        description="El intercambio a través del chat con los colegas desde el smartphone o el PC no es un problema y por supuesto cumple con la DSGVO."
        btnLabel="Aprende más."
        btnTarget="https://smartwerker.app/"
        img={feature3}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNCIÓN"
        title="Registro de tiempo"
        description="Registra todas las horas trabajadas en un proyecto. El acceso a los tiempos registrados en tiempo real, le da la posibilidad de preparar facturas en la oficina durante la jornada laboral."
        btnLabel="Aprende más."
        btnTarget="https://smartwerker.app/"
        img={feature4}
      />
      <FullScreenImage
        additionalClass=""
        img={feature5}
      />
      <AppVideo 
        title="Smartwerker en acción"
        description="Vea cómo Smartwerker puede ayudarle a usted y a su empresa."
        videoId="hTD4uRX31Xc"
      />
      <div id="start"/>
      <StartNow 
        title="Pruebe ahora gratis"
        description="Empieza ahora en unos pocos pasos y digitaliza tu negocio de artesanía! Utiliza Smartwerker en el navegador, en el smartphone o en la tableta. Descarga nuestra aplicación ahora desde el Apple App Store o el Google Play Store."
        btnLabel="PERDER AHORA"
      />
      <div id="testimonials"/>
      <SuccessStories 
        title="Historias de éxito"
      />
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
// query indexHomeQuery {
//   allContentfulSmartwerkerHome {
//     nodes {
//       id
//       title
//       headerImage {
//         fluid(maxWidth: 1600 quality: 50) {
//           src
//           srcSet
//           srcWebp
//           srcSetWebp
//           sizes
//           aspectRatio
//         }
//         description
//       }
//       components {
//         ... on ContentfulContentTeaser {
//           id
//           contenTeaserTitle
//           contenTeaserContent {
//             json
//           }
//           contenTeaserCarousel {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         ... on ContentfulPromotionTeaser2 {
//           id
//           promotionTeaserTitle
//           promotionTeaserImageRightTop {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageRightBottom {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserTitleImage {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomRight {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserContent {
//             json
//           }
//         }
//       }
//     }
//   }

//   allInstaNode {
//     edges {
//       node {
//         id
//         likes
//         comments
//         mediaType
//         preview
//         original
//         timestamp
//         caption
//         localFile {
//           childImageSharp {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         # Only available with the public api scraper
//         thumbnails {
//           src
//           config_width
//           config_height
//         }
//         dimensions {
//           height
//           width
//         }
//       }
//     }
//   }
// }
// `